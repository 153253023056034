/***React */
import { Link} from 'react-router-dom';
/**css */
import logo from '../../images/logo.png';
import '../scss/nav.scss';


function SubNavbar(props) { 

  
    return (
        <>
<header >
    <div className='sub-nav'>

     <div   className='ux-brand'>
        <Link className="logo-link" to="/">
              <img src={logo}  alt="logo" />
               <p >{props.title.name}</p>
          </Link>
       </div>

    </div>
  </header>
  </>
   );
}

export default SubNavbar;