/** @format */

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Content from '../../data/meet-team.json';

function Random() {
	const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

	const getRandomList = (arr) => {
		let date = +new Date();
		date = date.toString().substr(0, 5);
		let dateString = `items_${date}`;

		if (window.localStorage.getItem(dateString) === null) {
			localStorage.setItem(dateString, JSON.stringify(arr));
			return arr;
		} else {
			return JSON.parse(window.localStorage.getItem(dateString));
		}
	};

	const RandomList = getRandomList(shuffle(Content));
	const [items, setItems] = useState(RandomList);

	return (
		<>
			{items.map((slide, i) => {
				return (
					<div
						className="card-col"
						key={i}
					>
						<div id="team-Card">
							<Link to={`/team/${slide.name}`}>
								<div className="img-con">
									<img
										src={
											process.env.PUBLIC_URL +
											'/assets/' +
											[slide.name.toLowerCase()] +
											'.jpg'
										}
										variant="top"
									/>
								</div>
								<div className="bodyText">
									<h1 className="title">{slide.name} </h1>
									<p className="content">{slide.content}</p>
									<button
										to={`/team/${slide.name}`}
										className="primary-btn"
									>
										Learn More
									</button>
								</div>
							</Link>
						</div>
					</div>
				);
			})}
		</>
	);
}

export default Random;
