/**
 * React
 *
 * @format
 */

import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

/**css */
import './teamDetail.scss';

import backArrow from '../../../images/goHomeArrow.png';
/***component */
import CardSlider from '../../../components/js/slider';
import SubNavbar from '../../../components/js/sub-nav';
/****Json */
import TeamContent from '../../../data/meet-team.json';
import Nav from '../../../data/nav.json';

function TeamDetail() {
	let { name } = useParams();

	let [Text, textUpdate] = useState(TeamContent);
	let [title, useTitle] = useState(Nav);
	let navigate = useNavigate();

	let MatchName = Text.find(function (itemsData) {
		if (itemsData.name == name) return true;
	});

	return (
		<>
			<SubNavbar
				title={title[1]}
				i={1}
			/>

			<main>
				<span
					className="goHome"
					onClick={() => {
						navigate('/');
					}}
				>
					<img src={backArrow} /> Back to home
				</span>
			</main>

			<div className="bg">
				<div className="bg-con">
					<div id="team-detail">
						<div className="detail-img">
							<img
								src={
									process.env.PUBLIC_URL +
									'/assets/' +
									[name.toLowerCase()] +
									'.jpg'
								}
							/>
						</div>

						<div className="detail-text">
							<h1>{MatchName.name}</h1>
							<p>{MatchName.content}</p>
						</div>
					</div>
				</div>
			</div>

			<main className="slide-container">
				<div className="main-title">
					<h1>{title[1].subtitle}</h1>
					<Link
						to={'/team'}
						className="accent"
					>
						View All
					</Link>
				</div>
				<CardSlider initialSlide={MatchName.name} />
			</main>
		</>
	);
}

export default TeamDetail;
