/***React */
import { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { useParams} from 'react-router-dom';

/***image */
import backArrow from '../../images/leftArrow-white.png';
/**css */
import "./join.scss";
/***component */
import SubNavbar from '../../components/js/sub-nav';
/****Json */
import Nav from '../../data/nav.json';


function JobDetail() {
    let navigate = useNavigate();

    let {Id } = useParams();
    let [title, useTitle]=useState(Nav);

    const [Detail, setDetail] = useState();
    const JobDescription = () => {
        fetch('/api/recruitingCEJobRequisitionDetails?expand=all&onlyData=true&finder=ById;Id=%22' + Id + '%22,siteNumber=CX_2')
        .then((response) => response.json())
        .then((json) => {
                setDetail(json);
        });
    };

    useEffect( () => {
        JobDescription();
    }, [] );

    const RenderHTML = (props) => (
        <div>
            <p dangerouslySetInnerHTML={{__html:Detail.items[0].ExternalDescriptionStr} }></p>
            <h4>ABOUT US</h4>
            <p dangerouslySetInnerHTML={{__html:Detail.items[0].CorporateDescriptionStr} }></p>
        </div>
        )

return (
    <>
<SubNavbar title={title[4]} i={2} />

<div className='join-control'>
  <span className='arrow'  onClick={ ()=> {navigate(-1)}}>
          <img src={backArrow}  />
  </span>
  <br /><br />
</div>

<div>
<div className="job-bg" >
    <div className='width-control'>
        <div id="job-detail">
            <div className="detail-text ">

                    { Detail && Detail.items.map( (list,i)=>{
                    return(
                       <div key={i} >
                           <span>
                                <p>{list.Category}</p>
                                <h1 >{list.Title }</h1>
                                <span>{list.PrimaryLocation} </span>
                                {
                                    Detail.items[0].secondaryLocations.length > 0
                                    ?  <span>and {list.secondaryLocations.length} more</span>
                                    :null
                                }

                            </span>
                            <span>
                            <a  href={'https://hccz.fa.em3.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_2/job/'+ Id +'/apply/email?utm_medium=jobboard&utm_source=Dejobsmicrosite' } target="_blank" className='secondary-btn'> Apply</a>
                            </span>
                        </div>
                    )})}
                </div>
          </div>
    </div>
</div>

<main id='job-main'>
    <div className='job-description' >
          {  Detail && Detail.items.map( (description,index)=>{
               return(
                  <RenderHTML key={index} />
          )})}
        </div>
</main>

</div>
</>

    );
}

export default JobDetail;
