/** @format */

import {  useState } from "react";
import '../scss/filterDropdownOption.scss';


function DropdownOptions(props) {
	const {optionVal, handleChange } = props;

    return(

    <div className="select" >
        <input type="radio" name="location"  value="" />
        <span className="placeholder">Filter  </span>

        <label className="option option1">
            <input type="radio" name="location" value=""  onChange={handleChange}/>
            <span className="option-item">All</span>
        </label>
        <label className="option">
            <input type="radio" name="location"  value="US" onChange={handleChange}/>
            <span className="option-item">United States</span>
        </label>
        <label className="option">
            <input type="radio" name="location"  value="GB" onChange={handleChange}/>
            <span className="option-item">United Kingdom</span>
        </label>
        <label className="option">
            <input type="radio" name="location"  value="CA" onChange={handleChange}/>
            <span className="option-item">Canada</span>
        </label>
        <label className="option">
            <input type="radio" name="location"  value="AU" onChange={handleChange} />
            <span className="option-item">Australia</span>
        </label>
        <label className="option option6">
            <input type="radio" name="location"  value="IL" onChange={handleChange} />
            <span className="option-item">Israel</span>
        </label>
    </div>

    );
};

export default DropdownOptions;
