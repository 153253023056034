/***React */
import { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';

/**css */
import './value.scss';
/***image */
import backArrow from '../../images/leftArrow.png';
/***component */
import Navbar from '../../components/js/nav';
/****Json */
import MainData from '../../data/nav.json';
import ValueContent from '../../data/value.json';

function Values() {
    
  let [text] = useState(MainData);
  let navigate = useNavigate();


return (
    <>
<main >
     <Navbar text={text[2]} i ={3}/>

<span className='arrow'  onClick={ ()=> {navigate(-1)}}> 
    <img src={backArrow}  />
</span>

<section id='value-page'>
{
     ValueContent.map( (value,i) => {

        return(

          <div className="value-col" key={i} >
              <div  id="valueCard" > 
                    <div className="bodyText">
                          <h3 >{value.title}</h3> 

                          <div className='value-content'>
                              <p>   {value.content} </p>
                              <p>   {value.content2} </p>
                          </div>
                      </div>
                 </div>
           </div>
          )    
       })
      }
</section>

</main>


</>

    );
}

export default Values;
