/***React */
import { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
/**css */
import './jobScope.scss';
import 'react-tabs/style/react-tabs.css';
/***component */
import SubNavbar from '../../components/js/sub-nav';

/****Json */
import Nav from '../../data/nav.json';
import JobScopeTabs from '../../components/js/tabs';



function JobScope() {
    
    let [title, useTitle]=useState(Nav);
    let navigate = useNavigate();

return (
    <>

<SubNavbar title={title[3]} i={2} />

<section>
<JobScopeTabs/>

</section>
</>

    );
}

export default JobScope;


