/** @format */

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DropdownOptions from './filterDropdownOption';
import '../scss/job-opening.scss';

function FilterDropdown() {
	const [jobs, setJobs] = useState([]);
	const [dataOptions, setDataOptions] = useState({
		PrimaryLocationCountry: [],
	});

	const [filters, setFilters] = useState({
		PrimaryLocation: 'All',
	});

	const [isLoading, setIsLoading] = useState(true);

	const jobAPI = () => {
		let api =
			'/api/recruitingCEJobRequisitions?onlyData=true&expand=requisitionList.secondaryLocations,flexFieldsFacet.values&finder=findReqs;siteNumber=CX_2,facetsList=LOCATIONS%3BWORK_LOCATIONS%3BTITLES%3BCATEGORIES%3BORGANIZATIONS%3BPOSTING_DATES%3BFLEX_FIELDS,limit=24,keyword=%22user%20experience%22';

		fetch(api)
			.then((response) => response.json())
			.then((data) => {
				setJobs(
					data.items[0].requisitionList.map((ob, index) => ({
						...ob,
						PrimaryLocationCountry: ob.PrimaryLocationCountry,
					}))
				);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		jobAPI();
	}, []);

	useEffect(() => {
		const getArrayOfOptions = (colName) => [
			...new Set(
				jobs
					.filter((options) => applyFilters(options))
					.map((obj) => obj[colName] || 'empty')
			),
		];

		setDataOptions((prev) => ({
			...prev,
			PrimaryLocationCountry: getArrayOfOptions('PrimaryLocationCountry'),
		}));
	}, [jobs, filters]);

	const applyFilters = (options) =>
		Object.entries(filters).reduce(
			(fin, [k, v]) =>
				fin && (!v || options[k] === v || options[k] === +v || v === 'All'),
			true
		);

	return (
		<>
			{Object.entries(dataOptions).map(([i, v]) => (
				<DropdownOptions
					key={i}
					optionVal={filters[i]}
					handleChange={(ev) => {
						setFilters((prev) => ({
							...prev,
							[i]: ev.target.value,
						}));
					}}
					optionsList={v}
				/>
			))}

			<div id="job-group">
				{isLoading ? (
					<div className="spinner">
						<img
							src={process.env.PUBLIC_URL + 'Spinner.png'}
							alt="Loading..."
						/>
					</div>
				) : (
					<ul>
						{jobs
							?.filter((i) => applyFilters(i))
							?.map((list, index) => {
								return (
									<Link
										to={`/join/${list.Id}`}
										key={index}
									>
										<li>
											<h2 className="title">{list.Title}</h2>
											<p className="content">{list.PrimaryLocation} </p>
										</li>
									</Link>
								);
							})}

						{jobs?.filter((i) => applyFilters(i)).length === 0 && (
							<h2 className="not-eligible">
								There are currently no job openings available at this
								location, please check back later.
							</h2>
						)}
					</ul>
				)}
			</div>
		</>
	);
}

export default FilterDropdown;
