/***React */
import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
/**css */
import '../scss/tabs.scss';
/**component */
import Table from '../js/tables'
/**json */
import jobScopeData from '../../data/jobScope.json';

function JobScopeTabs() {

    let [tabContent, useTabContent]=useState(jobScopeData);    

    return(

<Tabs forceRenderTabPanel defaultIndex={0}>
    
        <TabList>
            {tabContent.map(({ tab }, i) => (
                <Tab key={i}>
                    {tab}
                </Tab>
            ))}
        </TabList>

     {tabContent.map(({ heading, subtitle, button, description }, i) => (
            <div id='tab-bg'  key={i}>
                    <TabPanel>
                        <span>PRODUCT DESIGN</span>
                        <h1>{heading}</h1>

                        <div className='description-box'>
                            <div><p>{description}</p></div>
                            <div className='sub-box'>
                                   <h5>{subtitle}</h5>
                                   <button className='secondary-btn'>{button}</button>
                            </div>
                        </div>
                    </TabPanel>
              </div>
                    ) )}
                    
        <Table/>
  
        </Tabs>
 
  );
}
  export default JobScopeTabs;