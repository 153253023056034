import React, { Component } from "react";
import { useNavigate, Link} from 'react-router-dom';
/***css */
import '../scss/valueSlide.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**Json */
import ValueContent from '../../data/value.json';


function NextArrow(props) {
   const { className, style, onClick } = props;
   return (
     <div
       className={className}
       style={{ ...style, display: "block"}}
       onClick={onClick}
     />
   );
 }
 function PrevArrow(props) {
   const { className, style, onClick } = props;
   return (
      <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
   );
 }

 export default class ValueSlide extends Component {

   render() {
     var settings = {
       dots: false,
       infinite: true,
       slidesToScroll: 1,
       slidesToShow: 5,
       swipe: true,
       nextArrow: <NextArrow />,
       prevArrow: <PrevArrow />,
       responsive: [
          {
            breakpoint: 1200,
             settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
         {
           breakpoint: 998,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 1,
           }
         },
         {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
         {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
       ]
     };



     return (
 <>
 <Slider {...settings} >
 {

      ValueContent.map((slideValue, i)=>{
      return(

      <div id="valueSlide" key={i}>
          <Link  to={`/values/`}>
               <div  className='value-body'>
                  <h1 className='value-title'>{slideValue.title}</h1>
                  <p className='value-content'>{slideValue.content}</p>
               </div>
           </Link>
      </div>

        )
     }
   )
}

   </Slider>

       </>
     );
   }
 }
