/***React */
import { Link} from 'react-router-dom';
/**css */
import logo from '../../images/logo.png';
import '../scss/nav.scss';


function Navbar(props) { 

  
    return (
        <>
<header >
    <div className='nav'>

      <Link className="logo-link" to="/">
          <div   className='ux-brand'>
              <img src={logo} className="logo" alt="logo" />
              <p >{props.text.name}</p>
         </div>
       </Link>
       
      <div className="nav-text">
          <h1>{props.text.page}</h1>
          <p>{props.text.content}</p>
      </div>

    </div>
  </header>
  </>
   );
}

export default Navbar;