/** @format */

import React, { useState } from 'react';

import '../scss/feedback.scss';

function Feedback() {
    
	const [modalOpen, setModalOpen] = useState(false);
	const [messageSent, setMessageSent] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		const textArea = document.querySelector('#message');
		const message = textArea.value;
		let data = {};
		// -----------
		// Send POST message here
		// -----------

		data.text = message;
		data.to = 'gpduxfeedback@grp.pearson.com';
		data.subject = 'Feedback from Career Paths';

		// don't use dev
		fetch('https://pearsonct.design/server/api/email', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": "*"
			},
		})
			.then((d) => {
				setMessageSent(true);
			})
			.catch((err) => {
				console.log(err);
				// show an error here
			});
	};

	const handleShowModal = (e) => {
		e.preventDefault();

		// Disable tabindex on all clickable things
		const keyboardfocusableElements = [
			...document.querySelectorAll(
				'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
			),
		].filter(
			(el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
		);

		keyboardfocusableElements.forEach((item) => {
			// Store the existing tabindex
			item.setAttribute(
				'data-previous-tabindex',
				item.getAttribute('tabindex')
			);

			// Prevent tabbing and focusing
			item.setAttribute('tabindex', '-1');
		});

		setModalOpen(true);

		setTimeout(() => {
			// focus the textarea
			const textArea = document.querySelector('#message');
			textArea.focus();
		}, 100);
	};

	const handleCloseModal = () => {
		// Return tabindex to what they were
		const keyboardfocusableElements = [
			...document.querySelectorAll('[data-previous-tabindex]'),
		];

		keyboardfocusableElements.forEach((item) => {
			// Get the previous tabindex
			var oldIndex = item.getAttribute('data-previous-tabindex');

			// Return to previous tabindex
			if (oldIndex === 'null') {
				item.removeAttribute('tabindex');
			} else {
				item.setAttribute('tabindex', oldIndex);
			}

			// Remove the previous data attribute
			item.removeAttribute('data-previous-tabindex');
		});

		setModalOpen(false);
		setMessageSent(false);
	};

	const Form = () => {
		return (
			<form
				id="feedback-form"
				onSubmit={handleSubmit}
			>
				<h2>Send Feedback</h2>
				<button
					type="button"
					className="btn close-btn"
					onClick={handleCloseModal}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.0001 10.5858L18.7176 3.86833C19.1081 3.47781 19.7413 3.47781 20.1318 3.86833C20.5223 4.25886 20.5223 4.89202 20.1318 5.28255L13.4143 12.0001L20.1317 18.7175C20.5223 19.108 20.5223 19.7412 20.1317 20.1317C19.7412 20.5223 19.108 20.5223 18.7175 20.1317L12.0001 13.4143L5.28246 20.1317C4.89194 20.5222 4.25877 20.5222 3.86825 20.1317C3.47772 19.7412 3.47772 19.108 3.86825 18.7175L10.5858 12.0001L3.86842 5.28263C3.47789 4.89211 3.47789 4.25894 3.86842 3.86842C4.25894 3.47789 4.89211 3.47789 5.28263 3.86842L12.0001 10.5858Z"
						/>
					</svg>
				</button>
				<label
					htmlFor="message"
					className="sr-only"
				>
				</label>
				<textarea
					name="message"
					id="message"
				></textarea>
				<button
					type="submit"
					className="btn"
				>
					Send
				</button>
			</form>
		);
	};

	const Thanks = () => {
		return (
			<div className="thanks">
				<h2>Thanks</h2>
				<button
					type="button"
					className="btn close-btn"
					onClick={handleCloseModal}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.0001 10.5858L18.7176 3.86833C19.1081 3.47781 19.7413 3.47781 20.1318 3.86833C20.5223 4.25886 20.5223 4.89202 20.1318 5.28255L13.4143 12.0001L20.1317 18.7175C20.5223 19.108 20.5223 19.7412 20.1317 20.1317C19.7412 20.5223 19.108 20.5223 18.7175 20.1317L12.0001 13.4143L5.28246 20.1317C4.89194 20.5222 4.25877 20.5222 3.86825 20.1317C3.47772 19.7412 3.47772 19.108 3.86825 18.7175L10.5858 12.0001L3.86842 5.28263C3.47789 4.89211 3.47789 4.25894 3.86842 3.86842C4.25894 3.47789 4.89211 3.47789 5.28263 3.86842L12.0001 10.5858Z"
						/>
					</svg>
				</button>
				<p>Your message has been sent</p>
				<button
					type="button"
					className="btn"
					onClick={handleCloseModal}
				>
					Close
				</button>
			</div>
		);
	};

	return (
		<>
			<div className="feedback-btn-container">
				<a
					href="/"
					role="button"
					className="feedback-btn"
					onClick={handleShowModal}
				>
					Feedback
				</a>
			</div>

			{modalOpen === true ? (
				<div className="modal-grid">
					<div className={`modal-container`}>
						{messageSent === false ? <Form /> : <Thanks />}
					</div>
				</div>
			) : null}
		</>
	);
}

export default Feedback;
