/**
 * React
 *
 * @format
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**css */
import './team.scss';

/***image */
import backArrow from '../../images/goHomeArrow.png';
/***component */
import Navbar from '../../components/js/nav';
import Random from '../../components/js/Random';
/****Json */
import MainData from '../../data/nav.json';

function Team() {
	let [text] = useState(MainData);
	let navigate = useNavigate();

	return (
		<>
			<main>
				<Navbar
					text={text[1]}
					i={2}
				/>

				<span className="goHome" onClick={() => {navigate('/');}}>
					<img src={backArrow} /> Back to home
				</span>

				<section id="teampage">
					<Random />
				</section>
			</main>
		</>
	);
}

export default Team;
