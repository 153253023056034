/***React */
import { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
// import ReadMoreReact from 'read-more-react';
import ReactReadMoreReadLess from "react-read-more-read-less";
/**css */
import './careerpaths.scss';
/***image */
import backArrow from '../../images/leftArrow.png';
/***component */
import Navbar from '../../components/js/nav';

/****Json */
import MainData from '../../data/nav.json';
import CareerPathContent from '../../data/career-path.json';


function CareerPath() {
    
    let [text] = useState(MainData);
    let navigate = useNavigate();

return (
    <>
<main>
    <Navbar text={text[3]} i ={4}/>

    <span className='arrow'  onClick={ ()=> {navigate(-1)}}> 
        <img src={backArrow}  />
    </span>

<section id='careerpath-page'>
    {
       CareerPathContent.map( (career, i) => { 

        return(

            <div className="career-col" key={i} >
               <div id="careerCard" > 
                  <Link to={'/jobscope'}><img src={ career.img } /></Link>

                      <div className="bodyText">
                        <h2>{career.title}</h2>   
                             <ReactReadMoreReadLess  
                                   charLimit={114}
                                   readMoreText={"view more"}
                                   readLessText={"view less"}
                                   readMoreClassName="view-more"
                                   readLessClassName="view-less" > 
                                    {career.content}
                            </ReactReadMoreReadLess>
                            <Link to={'/jobscope' }className='primary-btn' >Job Scope</Link>
                        </div>
                        
                   </div>
                </div>

           )
    } )
}
</section>

</main>

</>

    );
}

export default CareerPath;
