/***React */
import { useState } from 'react';
import { Link} from 'react-router-dom';

/**css */
import './main.scss';

/***component */
import Navbar from '../../components/js/nav';
import CardSlider from '../../components/js/slider';
import ValueSlide from '../../components/js/valueSlide';
import TeamResources from '../../components/js/teamResources';
import FilterDropdown from '../../components/js/filterDropdown';

/****Json */
import MainData from '../../data/nav.json'


function Home() {
    
    let [text] = useState(MainData);

return (
    <>
<main>

  <Navbar text={text[0]} i ={1}/>

<section>
    <div className='main-title'>
         <h1>{text[1].title}</h1>
         <Link to={ '/team' } className='accent' >View All</Link>
      </div> 
      <CardSlider/>
</section>

<section>
 <div className='main-title'>
         <h1>{text[3].title}</h1>
 </div> 
      <TeamResources/>
</section>


<section id='jobs-section'>
    <div className='main-title'>
        <h1>{text[4].title}</h1>
    </div> 


	<div className='dropdown-section'>
		<Link to={ '/join' } className='accent' >View All</Link>
        <FilterDropdown/>  

	</div>

</section>

</main>
</>
    );
}

export default Home;
