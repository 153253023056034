import './App.css';
import { Routes, Route} from 'react-router-dom';
/**components*/
import ScrollToTop from './components/js/scrollTop';
import Feedback from './components/js/feedback';
/**pages */
import Home from './pages/home/home';
import Team from './pages/team/team';
import TeamDetail from './pages/team/detail/teamDetail';
import Values from './pages/values/value';
import CareerPath from './pages/careerpaths/careerpaths';
import Join from './pages/join/join';
import JobDetail from './pages/join/joinDetail';
import JobScope from './pages/careerpaths/jobScope';

function App() {
  return (
<div className="App">

<ScrollToTop />

  <Routes>
      <Route path= "/" element={<Home/>} />
      <Route path= "/team" element={<Team/>} />
      <Route path = "/team/:name" element={<TeamDetail/>} />
      <Route path = "/values" element={<Values/>} />
      <Route path="/careerpaths" element={<CareerPath/>} />
      <Route path="/jobscope" element={<JobScope/>} />
      <Route path="/join" element={<Join/>} />
      <Route path="/join/:Id" element={<JobDetail/>} />
  </Routes>

  <Feedback/>

</div>
  );
}

export default App;
