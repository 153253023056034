import { useState } from 'react';
/*css*/
import '../scss/table.scss';
/**json */
import tableData from '../../data/table.json';

function Table() {

    let [tableContent, useTabContent]=useState(tableData);    

  return (
<>

<table className='table-job-scope' >

        <thead>
                <tr>
                {tableContent.map(({title} , i) => (
                <th key={i}><p>{title}</p></th>
                ))}
            </tr>
         </thead>

     <tbody>

        <tr>
           {tableContent.map(({row1} , i) => (
                <td key={i}><p>{row1}</p></td>
           ))}
        </tr>

        <tr>
        {tableContent.map(({row2line1,row2line2} , i) => (
                <td key={i}>
                   <p>{row2line1}</p>
                      <br/>  
                   <p>{row2line2}</p>
               </td>
           ))}
        </tr>

        <tr>
           {tableContent.map(({row3} , i) => (
                <td key={i}><p>{row3}</p></td>
           ))}
        </tr>
        <tr>
           {tableContent.map(({row4line1, row4line2} , i) => (
                <td key={i}>
                    <p>{row4line1}</p>
                        <br/>
                    <p>{row4line2}</p>
                </td>
           ))}
        </tr>
        <tr className='last-row'>
           <td><p>{tableContent[0].row5}</p></td>
           <td colSpan = "5">
               <ul>
                     <li>{tableContent[0].list1}</li>
                     <li>{tableContent[0].list2}</li>
                     <li>{tableContent[0].list3}</li>
                     <li>{tableContent[0].list4}</li>
                     <li>{tableContent[0].list5}</li>
                     <li>{tableContent[0].list6}</li>
                     <li>{tableContent[0].list7}</li>
                     <li>{tableContent[0].list8}</li>
                     <li>{tableContent[0].list9}</li>
                     <li>{tableContent[0].list10}</li>
               </ul>   
               <ul>
                     <li>{tableContent[1].list1}</li>
                     <li>{tableContent[1].list2}</li>
                     <li>{tableContent[1].list3}</li>
                     <li>{tableContent[1].list4}</li>
                     <li>{tableContent[1].list5}</li>
                     <li>{tableContent[1].list6}</li>
                     <li>{tableContent[1].list7}</li>
                     <li>{tableContent[1].list8}</li>
               </ul>   

           </td>
        </tr>
    
     </tbody>

</table>


</>
  );
}

export default Table;