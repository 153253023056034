/** @format */

import React, { useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/***scss */
import '../scss/slider.scss';
import './Random';
/**json */
import Content from '../../data/meet-team.json';

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block' }}
			onClick={() => {
				onClick();
			}}
		/>
	);
}

function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block' }}
			onClick={onClick}
		/>
	);
}

function CardSlider(props) {
	let navigate = useNavigate();
	const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

	const getRandomList = (arr) => {
		let date = +new Date();
		date = date.toString().substr(0, 5);
		let dateString = `items_${date}`;
		if (window.localStorage.getItem(dateString) === null) {
			localStorage.setItem(dateString, JSON.stringify(arr));
			return arr;
		} else {
			console.log('using localstorage');
			return JSON.parse(window.localStorage.getItem(dateString));
		}
	};

	const RandomList = getRandomList(shuffle(Content));
	const [items, setItems] = useState(RandomList);

	const getId = (name) => {
		let toReturn = 0;
		if (name !== undefined) {
			RandomList.forEach((person, i) => {
				if (name == person.name) {
					toReturn = parseInt(i) + 1;
				}
			});
		}
		return toReturn;
	};

	var settings = {
		dots: false,
		autoplay: false,
		autoplaySpeed: 2000,
		speed: 700,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipe: true,
		centerMode: true, 
		centerPadding: '2%',
		initialSlide: getId(props.initialSlide),
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 998,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					centerPadding: '1%',
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			<Slider {...settings} id="slider">
				{items.map((slide, i) => {
					return (
						<React.Fragment key={slide.id}>
							<div id="teamCard">
								<Link to={`/team/${slide.name}`}>
									<div className="img-con">
										<img
											src={
												process.env.PUBLIC_URL +
												'/assets/' +
												[slide.name.toLowerCase()] +
												'.jpg'
											}
											variant="top"
											className="card-img"
										/>
									</div>
									<div className="bodyText">
										<h1 className="title">{slide.name} </h1>
										<p className="content">{slide.content}</p>
										<button
											onClick={() => {
												navigate('/team/' + slide.id);
											}}
											className="primary-btn"
										>
											Learn More
										</button>
									</div>
								</Link>
							</div>
						</React.Fragment>
					);
				})}
			</Slider>
		</>
	);
}

export default CardSlider;
