/***React */
import { useState} from 'react';
import {useNavigate} from 'react-router-dom';
/**css */
import './join.scss';
import '../../components/scss/job-opening.scss';
/***image */
import backArrow from '../../images/goHomeArrow.png';
/***component */
import Navbar from '../../components/js/nav';
import FilterDropdown from "../../components/js/filterDropdown";
/****Json */
import MainData from '../../data/nav.json';

function Join() {

    let [text] = useState(MainData);
    let navigate = useNavigate();

return (
    <>
<main >

<Navbar text={text[4]} i ={5}/>

<section className='join-control'>
    <span className="goHome" onClick={() => {navigate('/');}}>
		<img src={backArrow} /> Back to home
	</span>
    
       <FilterDropdown/>  
</section>
</main>

</>

    );
}

export default Join;
